import React, { useContext } from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"
import classnames from "classnames"
import slug from "slug"

/* Import Global Context(s) */
import IntroContext from "~context/intro"

/* Import Global Hook(s) */
import { useWebsiteConfiguration } from "~queries/hooks"

/* Import Local Component(s) */
import MailingList from "./components/mailingList"
import VipToggle from "./components/vipToggle/vipToggle"

/* Import Local Style(s) */
import "./footer.scss"

// removes all non-integer characters from string
const phoneNumberFilter = phoneNumber => {
  return phoneNumber.replace(/\D/g, "")
}

const Footer = ({hideLegal = false }) => {
  const { emailAddress, phoneNumber, socialMediaPlatforms, legalOrder } =
    useWebsiteConfiguration()
  const [isIntroDone] = useContext(IntroContext)  

  return (
    <footer
      className={classnames("site-footer", {
        "is-intro-done": isIntroDone,
      })}
    >
      <div className="row grid">
        <div className="column">
          <div className="desktop-only">
            <p><Link to="/">Untitled Art</Link></p>
            <p><br /><Link to="/vip">VIP</Link></p>
          </div>
          <p className="mobile-only"><Link to="/">Untitled Art</Link></p>
          <p className="mobile-only"><Link to="/vip">VIP</Link><br /></p>
          <a
            href={`tel:${phoneNumberFilter(phoneNumber)}`}
            target="_blank"
            rel="noreferrer"
            className="mobile-only"
          >
            {phoneNumber}
          </a>
          <a
            href={`mailto:${emailAddress}`}
            target="_blank"
            rel="noreferrer"
            className="mobile-only"
          >
            {emailAddress}
          </a>
        </div>
        <div className="column">
          <MailingList />
          <a
            href={`mailto:${emailAddress}`}
            target="_blank"
            rel="noreferrer"
            className="desktop-only"
          >
            {emailAddress}
          </a>
          <a
            href={`tel:${phoneNumberFilter(phoneNumber)}`}
            target="_blank"
            rel="noreferrer"
            className="desktop-only"
          >
            {phoneNumber}
          </a>
        </div>
        <ul className="column">
          {socialMediaPlatforms.map(({ platform, url, _key }) => (
            <li key={_key}>
              <a href={url} target="_blank">{platform}</a>
            </li>
          ))}
        </ul>
        {!hideLegal && <div className="column">
                  {legalOrder.filter(l => l != null).map(({pageTitle, shortTitle}) => (
                    <p key={slug(pageTitle)}><Link to={'/legal/' + slug(pageTitle)}>{ shortTitle !== null ? shortTitle : pageTitle }</Link></p>
                  ))}
                  
                </div>}
      </div>
      <div className="row art">
        {/*<p><Link className="footer_home_link" to="/">Art</Link></p>*/}
      </div>
    </footer>
  )
}

export default Footer
