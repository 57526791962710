import React, { useState, useEffect, useRef } from "react"
import { useLocation, navigate } from "@reach/router"

import classnames from "classnames"
import { useForm } from "react-hook-form"
import axios from "axios"
import { motion, AnimatePresence } from "framer-motion"

/* Import Local Style(s) */
import "./mailing-list.scss"
import CrossSvgSrc from "~assets/images/close.svg"

import MailingListModal from "~components/footer/components/mailingListModal/mailingListModal"

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

const MailingList = ({}) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const formRef = useRef()
  const location = useLocation()

  useEffect(() => {
    if(location?.hash == '#newsletter'){
      setIsActive(true)
    }else{
      setIsActive(false)
    }

  }, [location])



  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const handleClick = () => {
    setIsActive(true)

    // enable hash
    navigate(location.pathname + '#newsletter')

  }

  const closeModal = () => {
    setIsActive(false)

    // disable hash
    navigate(location.pathname)

  }

  const onSubmit = data => {
    const { email } = data
    axios
      .post("/.netlify/functions/subscribe", {
        email,
      })
      .then(response => {
        setIsSubmitted(true)
      })
      .catch(function (error) {
        console.log("error", error)
        if (error.response.data.detail) {
          if (error.response.data.detail.includes("already a list member")) {
            console.log(`${email} is already subscribed to Untitled Art`)
          }
        } else if (error.response.data.message) {
          console.log(error.response.data.message)
        } else {
          console.log("Error signing up for Untitled Art Email List")
        }
      })
  }

  if (isSubmitted) {
    return (
      <div className={"mailing-list"}>
        <p>Thanks!</p>
      </div>
    )
  }

  if (!isActive) {
    return (
      <button className={"mailing-list"} onClick={handleClick}>
        Join our mailing list
      </button>
    )
  } else {
    
    return (<AnimatePresence exitBeforeEnter>
        <motion.div
          key="mailinglist"
          initial="initial"
          animate="animate"
          exit="exit"
          variants={elementTransitionAnimation.variants}
        >
        <div className="mailing-list--modal">
          <button className="mailing-list_close" onClick={closeModal}>
            <img
              src={CrossSvgSrc}
              className="close"
              alt="cross"
              key="cross"
              exit="exit"
            />
          </button>
          <MailingListModal />          
          </div>
        </motion.div>
        
      </AnimatePresence>)

    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classnames("mailing-list")}
        ref={formRef}
      >
        <input
          type="email"
          placeholder=""
          {...register("email", { required: true })}
        />
        <input type="submit" value="Submit" />
      </form>
    )
  }
}

export default MailingList
