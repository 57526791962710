import React, { useState, useRef } from "react"
import classnames from "classnames"
import { useForm, Controller } from "react-hook-form"
import axios from "axios"

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

import { Countries } from '~components/pages/profile/country-iso.js'

/* Import Local Style(s) */
import "./mailing-list-modal.scss"

const MailingListModal = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const formRef = useRef()
  const [emailAddress, setEmailAddress] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const [defaultCountry, setDefaultCountry] = useState('US')

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm()

  const watchAgree = watch("agree", false);
  const watchCell = watch("phone", '')
  const watchCellAgree = watch("cellAgree", false)

  const setFieldValue = key => {

    const currentVal = getValues(key)

    if(currentVal === false){
      setValue(key, true)
    }else if(currentVal === true){
      setValue(key, false)
    }

  }

  const triggerDefaultCountry = e => {

    const currentVal = getValues('country')
    setDefaultCountry(currentVal)

  }

  const onSubmit = data => {

    const { email, firstname, lastname, city, country, phone } = data
    let contents = { email, firstname, lastname, city, country, phone, sms_opt_in: true }

    // console.log(email, firstname, lastname, city, country, phone)
    

    setIsSubmitting(true)

    axios
    .post("/.netlify/functions/vipUsers", {
      contents,
      method: 'post',
      path: '/newsletter/subscriptions'
    })
    .then(res => {

      setIsSubmitted(true)
      setEmailAddress(email)
      setIsSubmitting(false)

      console.log('request response: ')
      console.log(res.data)

    })
    .catch(e => {
      console.log("error", e)
      console.log("Error signing up for Untitled Art Email List")
      setErrorMessage("Error signing up for Untitled Art Email List")
      setIsSubmitting(false)
    })


    return

    axios
      .post("/.netlify/functions/subscribe", {
        email,
      })
      .then(response => {
        setIsSubmitted(true)
        setEmailAddress(email)
      })
      .catch(function (error) {
        console.log("error", error)
        if (error.response.data.detail) {
          if (error.response.data.detail.includes("already a list member")) {
            setErrorMessage(`${email} is already subscribed to Untitled Art`)
            console.log(`${email} is already subscribed to Untitled Art`)
          }
        } else if (error.response.data.message) {
          console.log(error.response.data.message)
          setErrorMessage(error.response.data.message)
        } else {
          console.log("Error signing up for Untitled Art Email List")
          setErrorMessage("Error signing up for Untitled Art Email List")
        }
      })
  }


  const retry = () => {
    setErrorMessage('')
  }

  return (
    <div className="mailing-list--interior">
        <h1 className="mailing-list--header">Untitled Art</h1>
        { !isSubmitted && errorMessage === '' && <form
          onSubmit={handleSubmit(onSubmit)}
          className={classnames("mailing-list", "font-size--small")}
          ref={formRef}
        >
          
          <fieldset className="grid">
            <input
              type="text"
              className={ (errors.firstname) ? 'input_error col col--1_2' : 'col col--1_2' }
              placeholder="First Name*"
              {...register("firstname", { required: true })}
            />

            <input
              className={ (errors.lastname) ? 'input_error col col--1_2  col--end' : 'col col--1_2  col--end' }
              type="text"
              placeholder="Last Name*"
              {...register("lastname", { required: true })}
            />
          </fieldset>

          <input
            type="email"
            className={ (errors.email) ? 'input_error' : '' }
            placeholder="Email Address*"
            {...register("email", { 
              required: true,
              pattern: /(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,15}/
            })}
          />

          <fieldset className="grid">
            <input
              className={ (errors.city) ? 'input_error col col--1_2' : 'col col--1_2' }
              type="text"
              placeholder="City*"
              {...register("city", { required: true })}
            />
            <select
              name="country"
              className={classnames(["col col--1_2 col--end"])}
              placeholder="Country*"
              {...register("country", { 
                required: true, 
                value: "",
                onChange: triggerDefaultCountry,
              })}
            >
              <option value="" disabled >Country*</option>

              { [].concat(Countries).map(c => (<option key={c['alpha-2']} value={c['alpha-2']} >{c.name}</option>)) }
            </select>            
          </fieldset>

          <fieldset className="grid mb--0">
          
          <div className={classnames(['col col--full phone_input_wrapper mb--1_2', {input_error: errors.phone}])}>
            <Controller
              name="phone"
              control={control}
              rules={{
                required: true,
                validate: (value) => isValidPhoneNumber(value ? value : '')
              }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  placeholder="Cell Phone*"
                  defaultCountry={defaultCountry}
                  value={value}
                  onChange={onChange}
                  id="phone-input"
                />
              )}
            />
          </div>

             
          <span className={classnames({checked: watchCellAgree})}>
            <input
              type="checkbox"
              name="type-of-art"
              className={ (errors.cellAgree) ? 'input_error square_checkbox' : 'square_checkbox' }
              {...register('cellAgree', { required: true })}
            />
          </span>
          <p 
            className="font-size--s checkbox_confirm" 
            className={(errors.cellAgree) ? 'label_error font-size--s checkbox_confirm' : 'font-size--s checkbox_confirm'}
            onClick={() => setFieldValue('cellAgree') }
          >
            I agree to receive SMS communications from Untitled Art.
          </p>
                        
          </fieldset>

          <fieldset className="grid mb--0">
            <span className={classnames({checked: watchAgree})}>
              <input
                type="checkbox"
                name="type-of-art"
                className={ (errors.agree) ? 'input_error square_checkbox' : 'square_checkbox' }
                {...register('agree', { required: true })}
              />
            </span>
            <p 
              className="font-size--s checkbox_confirm" 
              className={ errors.agree ? 'font-size--s label_error checkbox_confirm' : 'font-size--s checkbox_confirm'}
              onClick={() => setFieldValue('agree') }
            >
              I agree to receive email communications from Untitled Art<br/>and accept our <Link to="/legal/privacy">Privacy Policy</Link> & <Link to="/legal/terms-of-use">Website Terms of Use</Link>.
            </p>
          </fieldset>

          {!isSubmitting && <input type="submit" value="Submit" />}

          {isSubmitting && <p className="font-size--small left">Submitting...</p>}

        </form> }
        {isSubmitted && <div className="ml--submitted">
          { emailAddress !== '' && <p>Thank you, {emailAddress} has been subscribed.</p> }
          { emailAddress === '' && <p>It appears your email was not submitted. Please reload the page and try again.</p> }
        </div>}
        {errorMessage !== '' && <div className="ml--submitted font-size--s">
          <p className="font-size--s">{errorMessage}</p>
          <p><button className="font-size--s" onClick={retry}>Reset?</button></p>
        </div>}
    </div>
  )
}

export default MailingListModal
